import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import Hero from 'src/sections/Policy/Hero';
import PoliciesSection from 'src/sections/Policy/PoliciesSection';
import ApiWrapper from 'src/components/ApiWrapper';

const PoliciesPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Terms Of Use - Bizwise" />
      <Hero />
      <PoliciesSection />
    </Layout>
  </ApiWrapper>
);

export default PoliciesPage;
