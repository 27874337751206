import React from 'react';
import { createUseStyles } from 'react-jss';
import { breakpoint, spacing } from 'src/theme';
import Typography from 'src/components/core/Typography';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    height: 'auto',
    background: 'linear-gradient(119.13deg, #13266D 47.32%, #5FBCFF 163.26%)',
    paddingBottom: spacing(24),
    [breakpoint.down('sm')]: {
      paddingBottom: spacing(16),
    },
  },
  shape: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    mixBlendMode: 'overlay',
    [breakpoint.down('md')]: {
      display: 'none',
    },
  },
  heading: {
    textAlign: 'center',
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container} backgroundShape={2}>
      <NavbarSpacer />
      <Typography variant="h2" color="white" className={classes.heading}>
        Terms Of Use
      </Typography>
    </SectionContainer>
  );
};

export default Hero;
