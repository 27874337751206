interface Paragraph {
  html: string,
  heading?: string,
}

const privacyPolicy: Paragraph[] = [
  {
    html: `Last Updated: February 9, 2022
\nWelcome to the web site (the "Site") of Bizwise, Inc. ("Bizwise", "we", "us" and/or "our"). Bizwise provides an integrated platform of personnel management tools and services, mobile applications and related services (together with the Site, the "Services") to our Service visitors ("you" or "your").
\nThis Privacy Policy describes and is intended to help you understand our privacy principles, what information we collect (including Personal Data), how we use that information, how we share that information, your choices concerning our data practices, and other important information. We consider “Personal Data” to be any information that alone or in combination with other information in our possession could be used to identify you. This Privacy Policy does not apply to any third party applications or software that integrate with the Services through the Bizwise platform (“Third Party Services”), which are governed by their own respective privacy policies. When you access our Services through your employer or another entity (our “Customer”), please note that our Customers maintain control over the Services accounts for their organization, including the data associated with their account. If you have any questions about the specific settings and privacy practices of a Customer account, please contact the Customer whose account you use for more information.
\nBefore using the Service or submitting any information to us, please review this Privacy Policy carefully and contact us if you have any questions. If you do not agree with the terms, please do not access or use the Services, Site, or any other aspect of Bizwise’s business.
`,
  },
  {
    heading: '1. OUR PRIVACY PRINCIPLES',
    html: `We recognize that you must entrust us with information about you to receive our Services. Maintaining your trust is extremely important to us. We share our privacy principles to enable you to understand the key hallmarks of Bizwise’s approach to data privacy.
<ul>
<li>We only use your Personal Data in connection with our Services. We act as a “data processor” or “service provider” (where applicable) with respect to data transmitted to us by our customers and their employees and contractors. We will process your Personal Data only in accordance with our Terms of Service, Data Processing Addendum, and this Privacy Policy.</li>
<li>We safeguard the security of your Personal Data as if it were our own. We believe that security certifications are good, but that security by design is better. We bake security into our product features and protect your data with the same security safeguards used to protect our own data.</li>
<li>We do not sell your Personal Data. We recognize that receiving your Personal Data is vital to provide our Services to you. Selling Personal Data would be a grave violation of trust. We want to make clear that we are not in the business of selling Personal Data and we do not intend to be.</li>
</ul>
`,
  },
  {
    heading: '2. INFORMATION WE COLLECT',
    html: `When you interact with us through the Site and Services, we may receive Personal Data as well as other information and data (“Other Information”), as explained in more detail below.
<ul>
<li>Information You Provide Us. We collect your Personal Data when you, your employer, or your employer’s vendors register to use the Service, provide information when using the Site or Service, update your account information, add additional services, submit information to verify your identity, contact customer support, contact us with questions or feedback, or otherwise communicate with us. This Personal Data may vary depending on what you choose to share with us and the Services you use, and may include identification information, professional or employment-related information, benefits enrollment information, financial information, demographic information, commercial information, photographs, online activity information, geolocation data, background screening information, and preference information. If you or your employer manages or obtains health insurance benefits through our Services, we may also receive health information about you and your dependents to assist you with eligibility and enrollment in insurance coverage, which we may obtain directly from you, from the primary policyholder on your benefits plan if you are a spouse or dependent of the primary policy holder, from your employer, from insurance carriers, from third-party administrators, or from other service providers used to provide Bizwise’s benefits administration and broker services.</li>
<li>Information Third Parties Provide Us. We may collect and receive information about you, including Personal Data, from third parties, such as your employer, your employer’s service providers, financial institutions, and our own service providers, including for identity verification, fraud protection, providing the Service, and other purposes. We may also receive demographic and business industry information about you from third parties to help us better understand our users and to improve and market our Service. If you use us to manage integrated Third Party Services, we may also have access to your Personal Data from such Third Party Services. For example, if you use an integrated Third Party Service to conduct employee and contractor background checks, we may receive the background reports from that integrated Third Party Service in order to display the background check results to you.</li>
<li>Other Information: We may also collect, generate, and/or receive Other Information from the Site or Services as follows:</li>
<ul><li>Services Metadata. When you interact with the Services, metadata is generated that provides additional context about the way our authorized users utilize the Services. For example, we may log what Third Party Services are used (if any).</li>
<li>Log Data. As with most websites and technology services delivered over the Internet, our servers automatically collect information when you access or use our Site or Services and record it in log files. Such log files may include the Internet Protocol (IP) address, the address of the web page visited before using the Website or Services, browser type and settings, the date and time Services were used, information about browser configuration and plugins, language preferences and cookie data.</li>
<li>Device Information. We collect information about devices accessing the Services, including type of device, what operating system is used, device settings, application IDs, unique device identifiers and crash data. Whether we collect some or all of this Other Information often depends on the type of device used and its settings.</li>
<li>Location Information. We receive information from you, your Customer and other third-parties that helps us approximate your location. We may, for example, use a business address submitted by your employer, or an IP address received from your browser or device to determine approximate location. We may also collect location information from devices in accordance with the consent process provided by your device.</li>
<li>Cookie Information: We use cookies and similar technologies to operate and administer our Services, gather and analyze usage, and improve your experience. We may also allow third party service providers to use cookies or similar technologies to collect information about your browsing activities over time and across different websites following your use of the Services. For example, we use Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help us analyze how users use the Site and enhance your experience when you use the Service. For more information on how Google uses this data, go to www.google.com/policies/privacy/partners. Cookies can be stored on your computer for different periods of time. Some cookies expire after a certain amount of time, or upon logging out (session cookies), others survive after your browser is closed until a defined expiration date set in the cookie (as determined by the third party placing it), and help recognize your computer when you open your browser and browse the Internet again (persistent cookies). For more details on cookies please visit All About Cookies.</li>
<li>Additional Information Provided to Us. We also may receive Other Information when submitted to our Site or in other ways, such as if you participate in a focus group, contest, activity or event, apply for a job, enroll in a certification program or other educational program hosted by Bizwise or a vendor, request support, interact with our social media accounts or otherwise communicate with us.</li>
</ul>
</ul>
`,
  },
  {
    heading: '3. HOW WE USE INFORMATION',
    html: `Bizwise uses information that we collect in accordance with Customer instructions, including our Terms of Service and related agreements, if applicable, Customer’s use of Services functionality, and as required by law. Bizwise acts as the “data processor” or “service provider,” where applicable, of Personal Data, and Customer acts as the “controller” or “business,” where applicable. A Customer may, for example, use the Services to assign roles and configure settings, access, modify, export, share and remove Personal Data and otherwise apply its policies to the Services.
More specifically, we may use and share the information we collect as follows:
<ul>
<li>To provide our Services. We use the information we collect to provide, update, maintain, and protect the Services. For example, if you participate in our benefits administration or broker services, we may use health information to confirm insurance enrollments, identify suitable benefits products, and carry out insurance payments, health care, and other benefits-related services and operations.</li>
<li>To improve and customize the Site and Services. As we seek to improve your experience with the Site and Services, we may analyze data we collect about your use of the Site or Services to inform how we can improve our Site or Services, including our software, infrastructure, user interfaces, and integrations with our partners.</li>
<li>To respond to inquiries, comments, feedback, or questions.If you contact us, we may use the information that you provide to respond.</li></li>
<li>To communicate with you. We may send you service, technical and other administrative emails, messages, and other types of communications. We may also contact you to inform you about changes in our Services, our Services offerings, and important Services-related notices, such as security and fraud notices. These communications are considered part of the Services and you may not opt out of them.</li>
<li>To advertise and market to you. We may contact you to tell you about new or relevant product features, to send promotional communications to you, to send gifts or other marketing communications to you, and to provide other news we believe may be of interest to you. Additionally, as noted above, we may also utilize cookies, beacons, and mobile SDKs, to recognize your browser or mobile device in order to serve you advertisements, and analyze the effectiveness of our advertising and marketing campaigns. We may also use third party services, data, and software tools to provide advertising to you, help us identify and reach potential customers, and perform analysis on the performance of our Site and advertising. Our advertising vendors do not have any independent right to use Bizwise data or that of its customers. Please note that you can control whether you receive marketing communications and advertisements by following the instructions under “Your Choices” below.</li>
<li>To investigate and help prevent security issues and abuse of our Services. We may use your Personal Data and Other Information to protect the rights, property, and safety of Bizwise, our employees, Customers, our users, and third parties, including enforcing contracts or policies, or in connection with investigating and preventing fraud or security issues.</li>
<li>To comply with applicable law, regulation, or legal process. We may use your Personal Data and Other Information as permitted by law or reasonably necessary to comply with applicable law (e.g. civil or criminal subpoenas, court orders, and other compulsory orders), and to enforce this Privacy Policy, our Terms of Service, and other related agreements.</li>
<li>With consent. We may use Personal Data and Other Information with third parties when we have consent to do so.</li>
</ul>
`,
  },
  {
    heading: '4. HOW WE SHARE INFORMATION',
    html: `Bizwise is not in the business of selling your information. We consider maintaining your trust over your information to be a vital part of our relationship with you. In certain circumstances, however, Bizwise may share the information we collect with third parties, including our service providers and sub-processors, for the legitimate business purposes set forth below.
<ul>
<li>To comply with Customer’s instructions. We may share and disclose Personal Data in accordance with a Customer’s instructions, including any applicable terms in the Terms of Service or related agreements, Customer’s use of Services functionality, and in compliance with applicable law and legal process. In addition, Customer administrators and other personnel may be able to access, modify, or restrict access to Personal Data and Other Information. This may include, for example, your employer using Service features to export logs of Services activity.</li>
<li>To integrate with Third Party Services. To provide an integrated platform of business management software solutions, Customers may enable integrated Third Party Services, including 401(k) providers, applicant tracking platforms, performance management services, corporate card providers, communication tools, among others. When enabled, Bizwise may share Personal Data and Other Information with Third Party Services. Third Party Services are not owned or controlled by Bizwise and third parties that have been granted access to Personal Data and Other Information may have their own policies and practices for its collection, use, and sharing. Please check the permissions, privacy settings, and notices for these Third Party Services or contact the provider for any questions. Any permissions you have granted to that Third Party Service will govern their use of your data.</li>
<li>To work with our vendors and service providers. We may engage third party companies or individuals as service providers, vendors, or business partners to process Personal Data and Other Information and support our business. These third parties may, for example, provide cloud computing and storage services, work as a contractor to support some development initiatives, or we may share business information to develop strategic partnerships with Third Party Service providers to support our common customers. Additional information about the sub-processors we use to support delivery of our Services is set forth in our Data Processing Addendum.</li>
<li>To facilitate a change in Bizwise’s business. As our business develops, we may be involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, sale of all or a portion of our assets, or transition of service to another provider, and the information we collect may be shared or transferred in the diligence or conclusion of any such corporate event.</li>
<li>To investigate and help prevent security issues and abuse of our Services. We may share your Personal Data and Other Information to protect the rights, property, and safety of Bizwise, our employees, Customers, our users, and third parties, including enforcing contracts or policies, or in connection with investigating and preventing fraud or security issues.</li>
<li>To comply with applicable law, regulation, or legal process. We may disclose your Personal Data and Other Information if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend the rights or property of Bizwise, prevent fraud, act in urgent circumstances to protect the personal safety of users of the Services or the public, or protect against legal liability.</li>
<li>With corporate affiliates. We may share information we collect with our corporate affiliates, parents and/or subsidiaries, provided that our affiliates, parents and/or subsidiaries use Personal Data and Other Information in a manner consistent with this Privacy Policy.</li>
<li>With consent. We may share Personal Data and Other Information with third parties when we have consent to do so.</li>
</ul>
`,
  },
  {
    heading: '5. YOUR CHOICES',
    html: `We respect your choices over your Personal Data. When visiting our Site or using our Services, you have the following choices with respect to your Personal Data.
<ul>
<li>Accessing the Site. You can visit our Site without providing any Personal Data.</li>
<li>Reviewing, Updating, and Correcting Your Information. You can review, update, or correct your Personal Data through your account. If you have questions, please contact us.</li>
<li>Marketing and Promotional Communications. You may unsubscribe from marketing emails that we send to you by following the instructions contained in each promotional email we send you. If you unsubscribe, you will no longer receive marketing emails, but we will continue to contact you regarding management of your account, other administrative matters, and to respond to your requests. You may also opt-out of online behavioral advertising on the Internet by visiting the Network Advertising Initiative, or the Digital Advertising Alliance opt-out pages. You may also opt out of online behavioral advertising on your mobile device by some mobile advertising companies and other similar entities by downloading the App Choices App. Depending on your mobile device, you may also control marketing and other tracking technologies through your settings by resetting your advertising ID.</li>
<li>Do Not Track. Some web browsers may transmit “Do Not Track” signals. There is no current standard that governs what, if anything, websites should do when they receive these signals. We do not currently respond to “Do Not Track” signals. If and when a standard is established, we may revise our policy on “Do Not Track” signals.</li>
</ul>
If you would like to take additional steps to correct, amend, object to the processing or profiling of, or to delete your Personal Data not currently available through the Bizwise platform, please coordinate your request with the Customer whose account you use (e.g. your employer or former employer). Because Bizwise acts as a service provider to our Customers, in some situations, we must hear from your employer before taking additional steps. Please note that your employer may or may not be able to honor such requests in order to maintain a legally compliant business management software record-keeping system. For example, employers in the United States are legally required to maintain records of various work eligibility, tax, and other employment documentation.
`,
  },
  {
    heading: '6. IMPORTANT INFORMATION',
    html: `a. Our Policy Toward Children
\nBizwise does not knowingly collect Personal Data from children under the age of 13, except to the extent necessary to provide our benefits administration and broker services. If you are under the age of 13, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to Bizwise through the Services, please contact us, and we will endeavor to delete that information from our databases.
\nb. Links to Other Websites
\nThis Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by Bizwise (the "Third Party Sites"). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of those Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these Third Party Sites. Please contact the Third Party Sites directly for information on their privacy practices and policies.
\nc. Security
\nWe implement commercially reasonable technical, administrative, and organizational measures to protect Personal Data both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction and to comply with applicable state and federal privacy and security laws. We also apply the same standards to your data that we apply to our own data. However, despite these efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed to prevent interception or misuse. As a result, we must also depend on you to protect your information. You should take special care in deciding what information you send to us via the Service or e-mail. Please keep this in mind when disclosing any Personal Data to us via the Internet. If you become aware of any breach of security or privacy, please notify us immediately.
\nd. Data Retention
\nWe keep Personal Data for as long as reasonably necessary for the purposes described in this Privacy Policy while we have a business need to do so, or if retention is reasonably necessary to comply with our legal obligations, meet regulatory requirements, resolve disputes, prevent fraud and abuse, or enforce this Privacy Policy, our Terms of Service, or related Customer agreements. We may also retain Personal Data, for a limited period of time, if requested by law enforcement.
\ne. Aggregated or Pseudonymized Data.
\nIn an ongoing effort to better understand and serve the users of our Services, we conduct research on our Customer demographics, interests and behavior based on the Personal Data and Other Information provided to us. Such research may be compiled and analyzed on an aggregated or pseudonymized basis. This aggregated or pseudonymized information does not identify you. We may share aggregated or pseudonymized data with affiliates and partners for business or research purposes. We may also disclose aggregated or pseudonymized user statistics in order to describe our services to current and prospective business partners, and to third parties for other lawful purposes.
\ne. California Privacy Rights Disclosure
\nPursuant to the California Consumer Privacy Act of 2018 (“CCPA”), we act as a “service provider” with respect to the Personal Data that we receive and process through our Services, and conduct our data processing in accordance with our Terms of Service and CCPA-compliant Data Processing Addendum, which contractually sets forth the terms of our data processing as between our Customers and us. We do not sell Personal Data.
\nIf you are a resident of California and use the Services as an employee or contractors of our Customer, you may also have additional notice rights under CCPA. In particular, CCPA provides employees and contractors residing in California with the right to be informed of the categories of Personal Data collected and the purposes for which such Personal Data is used. If you have any questions about the application of CCPA to you, please contact the Customer whose account you use for more information, including any applicable CCPA privacy notices.
\nIf you are a resident of California and have applied for a position at Bizwise, we may collect and use your Personal Data for human resources and business management purposes, including identifying and evaluating candidates for potential employment as well as for future roles that may become available, recordkeeping in relation to recruiting and hiring, ensuring compliance with legal requirements including diversity and inclusion requirements and practices, conducting background checks as permitted by applicable law, protecting our legal rights to the extent authorized or permitted by law, or emergency situations where the health or safety of one or more individuals may be endangered. Your Personal Data may be accessed by recruiters and interviewers. We use third party service providers to provide a recruiting software system. We also share your personal data with other third party service providers that may assist us in recruiting talent, administering and evaluating pre-employment screening and testing, and improving our recruiting practices.
\nIf you are a resident of California and believe we have collected your Personal Data outside of a “service provider” relationship, you may request to know more details about the categories or specific pieces of Personal Data we collect (including how we use and disclose this information), to delete your Personal Data (where applicable), and to not be discriminated against for exercising these rights. Please see this Privacy Policy for more details about our privacy practices. You may make a data request pursuant to your rights under CCPA by contacting us at privacy@bizwise.com. We will verify your request using the information associated with your account, including email address. Government identification may be requested. You may also designate an authorized agent to exercise these rights. Notwithstanding the foregoing, please note that if you do not have a Bizwise account, we may not have enough information about you to verify your data requests, and as such, may be unable to verify and honor your requests.
\nf. International Users
\nBy using our Service, you understand and acknowledge that your Personal Data may be transferred from your location to our facilities and servers in the United States. If applicable, our Data Processing Addendum, which includes Standard Contractual Clauses for EU-US transfers, will apply to any such data transfers. Please note that Bizwise does not specifically market or sell into countries in the EU and does not act as a data controller in our marketing or sales capaciting in the EU.
\ng. Changes to Bizwise's Privacy Policy
\nThe Services and our business may change from time to time. As a result, it may be necessary for Bizwise to make changes to this Privacy Policy. Bizwise reserves the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide any Personal Data. This Privacy Policy was last updated to be effective as of the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy indicate your agreement with the terms of such revised Privacy Policy.
`,
  },
  {
    heading: '7. CONTACT US',
    html: 'If you have any questions about our Privacy Policy or information practices, please feel free to contact us at privacy@bizwise.com or by mail addressed to Bizwise, Inc. 44 Tehama Street, 4th Floor, San Francisco, CA 94105.',
  },
];

const cookiePolicy: Paragraph[] = [
  {
    html: 'Hi, we’re Bizwise. You’re probably here because you’re interested to know more about how we use cookies, web beacons and other similar technologies. As a part of our commitment to upholding a high standard of transparency in our Privacy Policy, we’ve created this guide to explain the tracking technologies we use on our sites.',
  },
  {
    heading: 'What are cookies, web beacons, and similar technologies?',
    html: `Typically, there are two types of tracking technologies we might use on our sites:
<ol>
<li>Cookies: these aren’t the type you eat. They’re actually a small data file sent from a server to your web browser or mobile device that is stored on your browser cache or mobile device. There are ways you can control your cookies preferences and set whether you want to accept or reject cookies (see what your options are below).</li>
<li>Clear gifs, web beacons, web bugs: These are tiny graphics with a unique identifier similar in function to cookies, and are used to track the movements of web users between pages and websites. Unlike cookies, which are cached on a user’s computer, clear gifs, web beacons and web bugs are embedded invisibly on web pages and are about the size of a single pixel.</li>
</ol>
`,
  },
  {
    heading: 'What do we do with these technologies?',
    html: `We use these tracking technologies for a few general purposes like:
<ol>
<li>To allow our sites to function correctly.</li>
<li>To understand how our sites are functioning and to inform any improvements in performance and our services.</li>
<li>To enhance your experience on our sites and provide functionality that means for example you don’t have to re-enter your details each time you engage with our sites or to record what purchases you’ve made on our sites.</li>
<li>To gather relevant data that helps us deliver marketing and advertising content relevant to your interests.</li>
<li>We might sometimes partner with third-party services who may use various tracking technologies to provide certain services or features on our sites, including targeted online marketing or relevant on-site messaging. These third-party services use cookies to anonymously collect data and allow them to recognize your computer or mobile device each time you visit any of our sites. No personally identifiable information is collected by these cookies. The anonymous data they collect is kept separate from the personal information about you as a user that we collect.</li>
</ol>
`,
  },
  {
    heading: 'Your options when it comes cookies, web beacons and similar technologies',
    html: `You can change your web browser’s settings to reflect your cookie preferences. Use these links to find out more information about cookie settings for these common browsers:
<ol>
<li><a href="http://windows.microsoft.com/en-US/windows7/Block-enable-or-allow-cookies" target="_blank">Internet Explorer</a></li>
<li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" target="_blank">Firefox</a></li>
<li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Chrome</a></li>
<li><a href="https://support.apple.com/en-us/HT201265" target="_blank">Safari</a></li>
</ol>
If you have any questions about our privacy practices, please contact our Privacy Agent Bizwise in writing at 44 Tehama Street 4th Floor, San Francisco, CA 94105 or help@bizwise.com.
`,
  },
];

const termsOfService: Paragraph[] = [
  {
    html: `Bizwise, Inc. operates this Site to provide online access to information about Bizwise and the products, services, and opportunities we provide (the "Service"). By accessing and using this Site, you agree to each of the terms and conditions set forth herein ("Terms of Use"). Additional terms and conditions applicable to specific areas of this Site or to particular content or transactions are also posted in particular areas of the Site and, together with these Terms of Use, govern your use of those areas, content or transactions. These Terms of Use, together with applicable additional terms and conditions, are referred to as this "Agreement".
\nBizwise reserves the right to modify this Agreement at any time without giving you prior notice. Your use of the Site following any such modification constitutes your agreement to follow and be bound by the Agreement as modified. The last date these Terms of Use were revised is set forth below.
`,
  },
  {
    heading: 'Use of Site',
    html: 'You may use the Service, the Site, and the information, writings, images and/or other works that you see, hear or otherwise experience on the Site (singly or collectively, the "Content") solely for your non-commercial, personal purposes and/or to learn about Bizwise products and services. No right, title or interest in any Content is transferred to you, whether as a result of downloading such Content or otherwise. Bizwise reserves complete title and full intellectual property rights in all Content. Except as expressly authorized by this Agreement, you may not use, alter, copy, distribute, transmit, or derive another work from any Content obtained from the Site or the Service, except as expressly permitted by the Terms of Use.',
  },
  {
    heading: 'Copyright',
    html: 'The Site and the Content are protected by U.S. and/or foreign copyright laws, and belong to Bizwise or its partners, affiliates, contributors or third parties. The copyrights in the Content are owned by Bizwise or other copyright owners who have authorized their use on the Site. You may download and reprint Content for non-commercial, non-public, personal use only. (If you are browsing this Site as an employee or member of any business or organization, you may download and reprint Content only for educational or other non-commercial purposes within your business or organization, except as otherwise permitted by Bizwise, for example in certain password-restricted areas of the). You may not manipulate or alter in any way images or other Content on the Site.',
  },
  {
    heading: 'Trademarks',
    html: 'You are prohibited from using any of the marks or logos appearing throughout the Site without permission from the trademark owner, except as permitted by applicable law.',
  },
  {
    heading: 'Links to Third-Party Web Sites',
    html: 'Links on the Site to third party web sites or information are provided solely as a convenience to you. If you use these links, you will leave the Site. Such links do not constitute or imply an endorsement, sponsorship, or recommendation by Bizwise of the third party, the third-party web site, or the information contained therein. Bizwise is not responsible for the availability of any such web sites. Bizwise is not responsible or liable for any such web site or the content thereon. If you use the links to the web sites of Bizwise affiliates or service providers, you will leave the Site, and will be subject to the terms of use and privacy policy applicable to those web sites.',
  },
  {
    heading: 'Downloading Files',
    html: 'Bizwise cannot and does not guarantee or warrant that files available for downloading through the Site will be free of infection by software viruses or other harmful computer code, files or programs.',
  },
  {
    heading: 'Disclaimer of Warranties',
    html: 'Bizwise MAKES NO EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER WITH RESPECT TO THE SITE, THE SERVICE OR THE CONTENT. Bizwise EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WITH REGARD TO THE SITE, THE SERVICE, THE CONTENT, AND ANY PRODUCT OR SERVICE FURNISHED OR TO BE FURNISHED VIA THE SITE. Bizwise DOES NOT WARRANT THAT THE FUNCTIONS PERFORMED BY THE SITE OR THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, OR THAT DEFECTS IN THE SITE OR THE SERVICE WILL BE CORRECTED. Bizwise DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE CONTENT, OR THAT ANY ERRORS IN THE CONTENT WILL BE CORRECTED. THE SITE, THE SERVICE AND THE CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.',
  },
  {
    heading: 'Limitation of Liability',
    html: `IN NO EVENT WILL Bizwise BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR OTHER INDIRECT DAMAGES ARISING OUT OF (I) THE USE OF OR INABILITY TO USE THE SITE, THE SERVICE, OR THE CONTENT, (II) ANY TRANSACTION CONDUCTED THROUGH OR FACILITATED BY THE SITE; (III) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE SITE, THE SERVICE AND/OR THE CONTENT, (IV) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR (V) ANY OTHER MATTER RELATING TO THE SITE, THE SERVICE, OR THE CONTENT, EVEN IF Bizwise HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOU ARE DISSATISFIED WITH THE SITE, THE SERVICE, THE CONTENT, OR WITH THE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
\nBECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH STATES, Bizwise'S LIABILITY IS LIMITED AND WARRANTIES ARE EXCLUDED TO THE GREATEST EXTENT PERMITTED BY LAW, BUT SHALL, IN NO EVENT, EXCEED $100.00.
`,
  },
  {
    heading: 'Indemnification',
    html: 'Bizwise MAKES NO EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER WITH RESPECT TO THE SITE, THE SERVICE OR THE CONTENT. Bizwise EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WITH REGARD TO THE SITE, THE SERVICE, THE CONTENT, AND ANY PRODUCT OR SERVICE FURNISHED OR TO BE FURNISHED VIA THE SITE. Bizwise DOES NOT WARRANT THAT THE FUNCTIONS PERFORMED BY THE SITE OR THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, OR THAT DEFECTS IN THE SITE OR THE SERVICE WILL BE CORRECTED. Bizwise DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE CONTENT, OR THAT ANY ERRORS IN THE CONTENT WILL BE CORRECTED. THE SITE, THE SERVICE AND THE CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.',
  },
  {
    heading: 'Additional Terms of Service',
    html: 'If you purchase any paid Service from Bizwise, such purchases will be subject to the additional terms and conditions associated with such purchases. Please review the policies that govern your use of such Services.',
  },
  {
    heading: 'User Conduct',
    html: 'You agree to use the Site only for lawful purposes. You agree not to take any action that might compromise the security of the Site, render the Site inaccessible to others or otherwise cause damage to the Site or the Content. You agree not to add to, subtract from, or otherwise modify the Content, or to attempt to access any Content that is not intended for you. You agree not to use the Site in any manner that might interfere with the rights of third parties.',
  },
  {
    heading: 'Unsolicited Idea Submission Policy',
    html: `Bizwise or any of its employees do not accept or consider unsolicited ideas, including ideas for new advertising campaigns, marketing strategies, new or improved products, technologies, services, processes, materials, or new product names. We have found this policy necessary in order to avoid misunderstandings should Bizwise's business activities bear coincidental similarities with one or more of the thousands of unsolicited ideas offered to us. Please do not send your unsolicited ideas to Bizwise or anyone at Bizwise. If, in spite of our request that you not send us your ideas, you still send them, then regardless of what your posting, email, letter, or other transmission may say, (1) your idea will automatically become the property of Bizwise, without any compensation to you; (2) Bizwise will have no obligation to return your idea to you or respond to you in any way; (3) Bizwise will have no obligation to keep your idea confidential; and (4) Bizwise may use your idea for any purpose whatsoever, including giving your idea to others.
\nHowever, Bizwise does welcome feedback regarding many areas of Bizwise's existing businesses that will help satisfy customer's needs, and feedback can be provided through the many listed contact areas on the Site. Any feedback you provide shall be deemed a Submission under the terms in the User Supplied Information section above.
`,
  },
  {
    heading: 'User Supplied Information',
    html: 'Bizwise does not want to receive confidential or proprietary information from you via the Site. You agree that any material, information, or data you transmit to us or post to the Site (each a "Submission" or collectively "Submissions") will be considered non confidential and non proprietary. For all Submissions, (1) you guarantee to us that you have the legal right to post the Submission and that it will not violate any law or the rights of any person or entity, and (2) you give Bizwise the royalty-free, irrevocable, perpetual, worldwide right to use, distribute, display and create derivative works from the Submission, in any and all media, in any manner, in whole or in part, without any restriction or responsibilities to you.',
  },
  {
    heading: 'Password Security',
    html: 'If you register to become a Bizwise.com member, you are responsible for maintaining the confidentiality of your member identification and password information, and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your member identification and password.',
  },
  {
    heading: 'General Provisions',
    html: `a.Entire Agreement/No Waiver. These Terms of Use constitute the entire agreement of the parties with respect to the subject matter hereof. No waiver by Bizwise of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.
\nb. Correction of Errors and Inaccuracies. The Content may contain typographical errors or other errors or inaccuracies and may not be complete or current. Bizwise therefore reserves the right to correct any errors, inaccuracies or omissions and to change or update the Content at any time without prior notice. Bizwise does not, however, guarantee that any errors, inaccuracies or omissions will be corrected.
\nc. Enforcement/ Choice of Law/ Choice of Forum. If any part of this Agreement is determined by a court of competent jurisdiction to be invalid or unenforceable, it will not impact any other provision of this Agreement, all of which will remain in full force and effect. Any and all disputes relating to this Agreement, Bizwise's Privacy Policy, your use of the Site, any other Bizwise web site, the Service, or the Content are governed by, and will be interpreted in accordance with, the laws of the State of Delaware, without regard to any conflict of laws provisions.
\nd. Claims of Copyright Infringement.It is Bizwise's policy to take immediate action to address allegations of copyright infringement reported to us in accordance with applicable law, including but not limited to the United States Digital Millennium Copyright Act (Details can be found at http://copyright.gov ).To report an infringement, you must submit a written notice containing the following:
<ul>
<li>Identification of the copyrighted content alleged to be infringed upon in sufficient detail to specify the copyrighted work (i.e. url or publication information of original copyrighted work).</li>
<li>Identification of content subject to claim of copyright infringement, including the specific url of content submitted, posted, or displayed through the Services.</li>
<li>Contact information sufficient to allow Bizwise to contact you regarding your claim; including your name, address, telephone number, and email address.</li>
<li>A statement by you that you have a good faith belief that aforementioned use of copyrighted content is unauthorized.</li>
<li>A statement by you, under the penalty of perjury that all information provided in the notice is accurate, and that you are either the owner of the allegedly infringed copyright or a person authorized to act on the copyright owner's behalf.</li>
<li>A physical or electronic signature of the copyright owner or a person authorized to act on behalf of the copyright owner.</li>
</ul>
Send written notice to:
\nIf you have questions or concerns, please e-mail legal@bizwise.com`,
  },
];

const dataProtection: Paragraph[] = [
  {
    heading: '',
    html: `The customer agreeing to these terms (“Customer”) has entered into either a Terms of Use Agreement or SaaS Services Agreement with Bizwise,, Inc. (“Bizwise”) under which Bizwise has agreed to provide services to Customer (as amended from time to time, the "Agreement").

    This Data Protection Addendum, including its applicable Appendices (the “Addendum”) will be effective and replace any previously applicable data processing and security terms as of the Addendum Effective Date (as defined below). This Addendum forms part of the Agreement.
    
    Any capitalized term used but not otherwise defined in this Addendum shall have the meaning provided to it in the Agreement.
    
    1. Definitions
    
    For purposes of this Addendum, the terms below shall have the meanings set forth below. Capitalized terms that are used but not otherwise defined in this Addendum shall have the meanings set forth in the Agreement.
    
    1.1 “Addendum Effective Date” means the date on which the parties agreed to this Addendum.
    
    1.2 “Affiliate” means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity, where “control” refers to the power to direct or cause the direction of the subject entity, whether through ownership of voting securities, by contract or otherwise.
    
    1.3 “Audit Reports” has the meaning given in Section 5.4.4 (Audit Reports).
    
    1.4 “CCPA” means the California Consumer Privacy Act of 2018.
    
    1.5 “Customer Personal Data” means any personal data or personal information of data subjects contained within the data provided to or accessed by Bizwise by or on behalf of Customer or Customer end users in connection with the Services.
    
    1.6 “Global Data Protection Legislation” means the European Data Protection Legislation, CCPA, and LGPD as applicable to the processing of Customer Personal Data under the Agreement.
    
    1.7 “EEA” means the European Economic Area.
    
    1.8 “EU” means the European Union.
    
    1.9 “European Data Protection Legislation” means the GDPR and other data protection laws of the EU, its Member States, Switzerland, Iceland, Liechtenstein and Norway and the United Kingdom, applicable to the processing of Customer Personal Data under the Agreement.
    
    1.10 “GDPR” means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data of EU data subjects and on the free movement of such data, and repealing Directive 95/46/EC.
    
    1.11 “Information Security Incident” means a breach of Bizwise’s security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Customer Personal Data in Bizwise’s possession, custody or control. “Information Security Incidents” will not include unsuccessful attempts or activities that do not compromise the security of Customer Personal Data, including unsuccessful log-in attempts, pings, port scans, denial of service attacks, and other network attacks on firewalls or networked systems.
    
    1.12 “LGPD” means the Brazilian General Data Protection Law.
    
    1.13 “Standard Contractual Clauses” or “SCCs” has the meaning set for thin Appendix 3(Cross Border Transfer Solutions) of this Addendum.
    
    1.14 “Security Documentation” means all documents and information made available by Bizwise under Section 5.4.1 (Audits).
    
    1.15 “Security Measures” has the meaning given in Section 5.1.1 (Bizwise’s Security Measures).
    
    1.16 “Services” means the services and/or products to be provided by Bizwise to Customer under the Agreement.
    
    1.17 “Subprocessors” means third parties authorized under this Addendum to process Customer Personal Data in relation to the Services.
    
    1.18 “Term” means the period from the Addendum Effective Date until the end of Bizwise’s provision of the Services.
    
    1.19 “Transfer Solution” means the Standard Contractual Clauses or another solution that enables the lawful transfer of personal data to a third country in accordance with Article 45 or 46 of the GDPR.
    
    1.20 The terms “personal data”, “data subject”, “processing”, “controller”, “processor” and “supervisory authority” as used in this Addendum have the meanings given in the GDPR and LGPD, as applicable, and the terms “data importer” and “data exporter” have the meanings given in the Standard Contractual Clauses. The terms “personal information”, “Business”, and “Service Provider” have the meanings set forth in the CCPA.
    
    2. Duration of Addendum
    
    This Addendum will take effect on the Addendum Effective Date and, notwithstanding the expiration of the Term, will remain in effect until, and automatically expire upon, Bizwise’s deletion of all Customer Personal Data as described in this Addendum.
    
    3. Processing of Data
    
    3.1 Roles and Regulatory Compliance; Authorization.
    
    3.1.1 Processor and Controller Responsibilities. This Addendum only applies to the extent that we are processing Customer Personal Data on behalf of Customer. If the European Data Protection Legislation, LGPD, or CCPA apply to the processing of Customer Personal Data, the parties acknowledge and agree that:
    
    (a) the subject matter and details of the processing are described in Appendix 1;
    (b) Bizwise is a processor of that Customer Personal Data under the European Data Protection Legislation or LGPD, and/or a Service Provider with respect to that Customer Personal Data under the CCPA, as applicable;
    (c) Customer is a either a controller or processor of that Customer Personal Data under European Data Protection Legislation or LGPD, and/or a Business with respect to that Customer Personal Data under the CCPA, as applicable; and
    (d) each party will comply with the obligations applicable to it under the applicable Global Data Protection Legislation with respect to the processing of that Customer Personal Data.
    3.1.2 Authorization by Third Party Controller. If the European Data Protection Legislation applies to the processing of Customer Personal Data and Customer is a processor, Customer warrants to Bizwise that Customer’s instructions and actions with respect to that Customer Personal Data, including its appointment of Bizwise as another processor and its consent to Bizwise’s onward transfers of Customer Personal Data to its Subprocessors, have been authorized by the relevant controller.
    
    3.2 Scope of Processing.
    
    3.2.1 Customer’s Instructions. By entering into this Addendum, Customer instructs Bizwise to process Customer Personal Data only in accordance with applicable law: (a) to provide the Services; (b) as authorized by the Agreement, including this Addendum and its Appendices; and (c) as further documented in any other written instructions given by Customer and acknowledged in writing by Bizwise as constituting instructions for purposes of this Addendum.
    
    3.2.2 Bizwise’s Compliance with Instructions. Bizwise will only process Customer Personal Data in accordance with Customer’s instructions described in Section 3.2.1 (including with regard to data transfers) (“Customer’s Instructions”) unless the applicable Global Data Protection Legislation to which Bizwise is subject requires other processing of Customer Personal Data by Bizwise, in which case Bizwise will notify Customer (unless that law prohibits Bizwise from doing so on important grounds of public interest).
    
    4. Data Deletion
    
    4.1 Deletion on Termination. Unless otherwise set forth in the Agreement, upon expiration of the Term, Customer instructs Bizwise to delete all Customer Personal Data (including existing copies) from Bizwise’s systems as required by and in accordance with applicable law as soon as reasonably practicable, unless applicable law prevents Bizwise from deleting such data. To the extent that Customer is bound by laws or regulations that would require Bizwise to retain Customer Personal Data after expiration of the Term and Customer does not inform Bizwise of such retention obligations, Customer shall be solely liable for any deletion of such data by Bizwise in accordance with this Section 4.1.
    
    5. Data Security
    
    5.1 Bizwise’s Security Measures, Controls and Assistance.
    
    5.1.1 Bizwise’s Security Measures. Bizwise will implement and maintain technical and organizational measures to protect Customer Personal Data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to Customer Personal Data as described in Appendix 2 (the "Technical and Organizational Security Measures"). Bizwise may update or modify the Security Measures from time to time provided that such updates and modifications do not materially decrease the overall security of the Services.
    
    5.1.2 Security Compliance by Bizwise Staff. Bizwise will grant access to Customer Personal Data only to employees, contractors and Subprocessors who need such access for the scope of their performance and are subject to appropriate confidentiality arrangements.
    
    5.1.3 Bizwise’s Security Assistance. Bizwise will (taking into account the nature of the processing of Customer Personal Data and the information available to Bizwise) provide Customer with reasonable assistance necessary for Customer to comply with its obligations in respect of Customer Personal Data under Global Data Protection Legislation, including Articles 32 to 34 (inclusive) of the GDPR and Articles 6 and 46 of the LGPD, by:
    
    (a) implementing and maintaining the Security Measures in accordance with Section 5.1.1 (Bizwise’s Security Measures);
    (b) complying with the terms of Section 5.2 (Information Security Incidents); and
    (c) providing Customer with the Security Documentation in accordance with Section 5.4.1 (Reviews of Security Documentation) and the Agreement, including this Addendum.
    5.2 Information Security Incidents.
    
    5.2.1 Information Security Incident Notification. If Bizwise becomes aware of an Information Security Incident, Bizwise will: (a) notify Customer of the Information Security Incident without undue delay after becoming aware of the Information Security Incident; and (b) take reasonable steps to identify the case of such Information Security Incident, minimize harm and prevent a recurrence.
    
    5.2.2 Details of Information Security Incident. Notifications made pursuant to this Section 5.2 (Information Security Incidents) will describe, to the extent reasonably practicable, details of the Information Security Incident, including (i) the nature of the Information Security Incident including where possible, the categories and approximate number of data subjects concerned and the categories and approximate number of personal data records concerned; (ii) the name and contact details of the data protection officer or other contact point where more information can be obtained, (iii) the likely consequences of Information Security Incident; (iv) steps taken, or proposed to be taken, to mitigate the potential risks and steps Bizwise recommends Customer take to address the Information Security Incident including, where appropriate, measures to mitigate its possible adverse effects.
    
    5.2.3 Notification. Customer is solely responsible for complying with incident notification laws applicable to Customer and fulfilling any third party notification obligations related to any Information Security Incident(s).
    
    5.2.4 No Acknowledgement of Fault by Bizwise. Bizwise’s notification of or response to an Information Security Incident under this Section 5.2 (Information Security Incidents) will not be construed as an acknowledgement by Bizwise of any fault or liability with respect to the Information Security Incident.
    
    5.3 Customer’s Security Responsibilities and Assessment.
    
    5.3.1 Customer’s Security Responsibilities. Customer agrees that, without prejudice to Bizwise’s obligations under Section 5.1 (Bizwise’s Security Measures, Controls and Assistance) and Section 5.2 (Information Security Incidents):
    
    (a) Customer is solely responsible for its use of the Services, including:
    (i) making appropriate use of the Services to ensure a level of security appropriate to the risk in respect of the Customer Personal Data;
    (ii) securing the account authentication credentials, systems and devices Customer uses to access the Services; and
    (iii) securing Customer’s systems and devices Bizwise uses to provide the Services; and
    (iv) backing up its Customer Personal Data.
    (b) Bizwise has no obligation to protect Customer Personal Data that Customer elects to store or transfer outside of Bizwise’s and its Subprocessors’ systems (for example, offline or on-premises storage).
    5.3.2 Customer’s Security Assessment.
    
    (a) Customer is solely responsible for reviewing the Security Documentation and evaluating for itself whether the Services, the Security Measures and Bizwise’s commitments under this Section 5 (Data Security) will meet Customer’s needs, including with respect to any security obligations of Customer under the applicable Global Data Protection Legislation.
    (b) Customer acknowledges and agrees that (taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of the processing of Customer Personal Data as well as the risks to individuals) the Security Measures implemented and maintained by Bizwise as set out in Section 5.1.1 (Bizwise’s Security Measures) provide a level of security appropriate to the risk in respect of the Customer Personal Data.
    5.4 Reviews and Audits of Compliance.
    
    5.4.1 Audits. Customer may audit Bizwise’s compliance with its obligations under this Addendum up to once per year. In addition, to the extent required by the applicable Global Data Protection Legislation, including where mandated by Customer’s supervisory authority, Customer or Customer’s supervisory authority may perform more frequent audits (including inspections). Bizwise will contribute to such audits by providing Customer or Customer’s supervisory authority with the information and assistance reasonably necessary to conduct the audit, including any relevant records of processing activities applicable to the Services.
    
    5.4.2 Objections to Third Party Auditor. If a third party is to conduct the audit, Bizwise may object to the auditor if the auditor is, in Bizwise’s reasonable opinion, not suitably qualified or independent, a competitor of Bizwise, or otherwise manifestly unsuitable. Such objection by Bizwise will require Customer to appoint another auditor or conduct the audit itself.
    
    5.4.3 Request for Audit. To request an audit, Customer must submit a detailed proposed audit plan to Bizwise at least two weeks in advance of the proposed audit date. The proposed audit plan must describe the proposed scope, duration, and start date of the audit. Bizwise will review the proposed audit plan and provide Customer with any concerns or questions (for example, any request for information that could compromise Bizwise security, privacy, employment or other relevant policies). Bizwise will work cooperatively with Customer to agree on a final audit plan. Nothing in this Section 5.4 (Reviews and Audits of Compliance) shall require Bizwise to breach any duties of confidentiality.
    
    5.4.4 Audit Reports. If the requested audit scope is addressed in an SSAE 16/18/ISAE 3402 Type 2, AICPA SOC 2 (SOC for Service Organizations: Trust Services Criteria), ISO, NIST or similar audit report performed by a qualified third party auditor (“Audit Reports”) within twelve (12) months of Customer’s audit request and Bizwise confirms there are no known material changes in the controls audited, Customer agrees to accept those findings in lieu of requesting an audit of the controls covered by the report.
    
    5.4.5 Conduct of Audit. The audit must be conducted during regular business hours at the applicable facility, subject to the agreed final audit plan and Bizwise’s health and safety or other relevant policies, and may not unreasonably interfere with Bizwise business activities.
    
    5.4.6 Conditions of Audit. Customer will promptly notify Bizwise of any non-compliance discovered during the course of an audit and provide Bizwise any audit reports generated in connection with any audit under this Section 5.4 (Reviews and Audits of Compliance), unless prohibited by The applicable Global Data Protection Legislation or otherwise instructed by a supervisory authority. Customer may use the audit reports only for the purposes of meeting Customer’s regulatory audit requirements and/or confirming compliance with the requirements of this Addendum. The audit reports and any Bizwise information shared during the audit process are Confidential Information of the parties under the terms of the Agreement.
    
    5.4.7 Expenses of Audit. Any audits are at Customer’s expense. Customer shall reimburse Bizwise for any time expended by Bizwise or its Subprocessors in connection with any audits or inspections under this Section 5.4 (Reviews and Audits of Compliance) at Bizwise’s then-current professional services rates, which shall be made available to Customer upon request. Customer will be responsible for any fees charged by any auditor appointed by Customer to execute any such audit.
    
    5.4.8 Standard Contractual Clauses. The parties agree that this Section 5.4 (Reviews and Audits of Compliance) shall satisfy Bizwise’s obligations under the audit requirements of the 2021 Standard Contractual Clauses applied to Data Importer under Clause 8 and Clause 13(a) and to any Subprocessors under Clause 9.
    
    6. Impact Assessments and Consultations
    
    Bizwise will (taking into account the nature of the processing and the information available to Bizwise) reasonably assist Customer in complying with its obligations under The applicable Global Data Protection Legislation in respect of data protection impact assessments and prior consultation, including, if applicable, Customer’s obligations pursuant to Articles 35 and 36 of the GDPR, by:
    
    6.1 Audit Reports and Security Measures. Making available for review copies of the Audit Reports or other documentation describing relevant aspects of Bizwise’s information security program and the security measures applied in connection therewith; and
    
    6.2 Additional Information. Providing the information contained in the Agreement including this Addendum.
    
    7. Data Subject Rights
    
    7.1 Customer’s Responsibility for Requests. During the Term, if Bizwise receives any request from a data subject in relation to Customer Personal Data, Bizwise will, at its sole discretion, (i) advise the Customer of the request, (ii) advise the data subject to submit his or her request to Customer, and/or (iii) notify the data subject that his or her request has been forwarded to the Customer. Customer will be responsible for responding to any such request.
    
    7.2 Bizwise’s Data Subject Request Assistance. Bizwise will (taking into account the nature of the processing of Customer Personal Data) provide Customer with self-service functionality through the Services or other reasonable assistance as necessary for Customer to fulfil its obligation under The applicable Global Data Protection Legislation to respond to requests by data subjects, including if applicable, Customer’s obligation to respond to requests for exercising the data subject’s rights set out in in Chapter III of the GDPR, Articles 18 and 19 of the LGPD, or Section 1798.105 of the CCPA. Customer shall reimburse Bizwise for any such assistance beyond providing self-service features included as part of the Services at Bizwise’s then-current professional services rates, which shall be made available to Customer upon request.
    
    8. Data Transfers
    
    8.1 Data Storage and Processing Facilities. Bizwise may, subject to Section 8.2 (Transfers of Data Out of the EEA), store and process Customer Personal Data anywhere Bizwise or its Subprocessors maintains facilities.
    
    8.2 Transfers of Data Out of the EEA.
    
    8.2.1 Bizwise’s Transfer Obligations. If the storage and/or processing of Customer Personal Data (as set out in Section 8.1 (Data Storage and Processing Facilities)) involves transfers of Customer Personal Data out of the EEA, the United Kingdom or Switzerland, and the European Data Protection Legislation applies to the transfers of such data (“Transferred Personal Data”), the terms set forth in Appendix 3 (Cross Border Transfer Solutions) will apply. Bizwise will make such transfers in accordance with a Transfer Solution, and make information available to Customer about such Transfer Solution upon request.
    
    8.2.2 Customer’s Transfer Obligations. In respect of Transferred Personal Data, Customer agrees that if under European Data Protection Legislation Bizwise reasonably requires Customer to use another Transfer Solution offered by Bizwise (other than the Standard Contractual Clauses, which are attached hereto as Appendix 3 and incorporated by reference to the extent that Customer is transferring Customer Personal Data out of the EEA, the United Kingdom or Switzerland to Bizwise) and Bizwise reasonably requests that Customer take any action (which may include execution of documents) required to give full effect to such solution, Customer will do so.
    
    8.3 Disclosure of Confidential Information Containing Personal Data. If Customer has entered into Standard Contractual Clauses as described in Section 8.2 (Transfers of Data Out of the EEA), Bizwise will, notwithstanding any term to the contrary in the Agreement, make any disclosure of Customer's Confidential Information containing personal data, and any notifications relating to any such disclosures, in accordance with such Standard Contractual Clauses. For the purposes of the Standard Contractual Clauses, Customer and Bizwise agree that (i) Customer will act as the data exporter on Customer’s own behalf and on behalf of any of Customer’s entities and (ii) Bizwise or its relevant Affiliate will act on its own behalf and/or on behalf of Bizwise’s Affiliates as the data importers.
    
    9. Subprocessors
    
    9.1 Consent to Subprocessor Engagement. Customer generally authorizes the engagement of any other third parties as Subprocessors and authorizes onward transfer of Customer Personal Data to any Subprocessors engaged by Bizwise. If Customer has entered into Standard Contractual Clauses as described in Section 8.2 (Transfers of Data Out of the EEA), the above authorizations will constitute Customer’s prior written consent to the subcontracting by Bizwise of the processing of Customer Personal Data if such consent is required under the Standard Contractual Clauses.
    
    9.2 Information about Subprocessors. Information about Subprocessors, including their functions and locations, is available at https://Bizwise.com/dpa/subprocessors (as may be updated by Bizwise from time to time in accordance with this Addendum).
    
    9.3 Requirements for Subprocessor Engagement. When engaging any Subprocessor, Bizwise will enter into a written contract with such Subprocessor containing data protection obligations not less protective than those in the Agreement (including this Addendum) with respect to the protection of Customer Personal Data to the extent applicable to the nature of the Services provided by such Subprocessor. Bizwise shall be liable for all obligations subcontracted to, and all acts and omissions of, the Subprocessor.
    
    9.4 Opportunity to Object to Subprocessor Changes. When any new Subprocessor is engaged during the Term, Bizwise will, at least 30 days before the new Subprocessor processes any Customer Personal Data, provide notice of the engagement via email (including the name and location of the relevant Subprocessor and the activities it will perform). In order to receive email notifications related to Subprocessor changes, Customer may register using the portal found at https://Bizwise.com/dpa/subprocessors
    
    When any new Subprocessor is engaged during the Term, Bizwise will, at least 30 days before the new Subprocessor processes any Customer Personal Data, notify Customer of the engagement (including the name and location of the relevant Subprocessor and the activities it will perform).
    
    Customer may object to any new Subprocessor by providing written notice to Bizwise within ten (10) business days of being informed of the engagement of the Subprocessor as described above. In the event Customer objects to a new Subprocessor, Customer and Bizwise will work together in good faith to find a mutually acceptable resolution to address such objection. If the parties are unable to reach a mutually acceptable resolution within a reasonable timeframe, Customer may, as its sole and exclusive remedy, terminate the Agreement by providing written notice to Bizwise.
    
    10. Processing Records
    
    10.1 Bizwise’s Processing Records. Customer acknowledges that Bizwise is required under the GDPR to: (a) collect and maintain records of certain information, including the name and contact details of each processor and/or controller on behalf of which Bizwise is acting and, where applicable, of such processor’s or controller's local representative and data protection officer; and (b) make such information available to the supervisory authorities. Accordingly, if the GDPR applies to the processing of Customer Personal Data, Customer will, where requested, provide such information to Bizwise, and will ensure that all information provided is kept accurate and up-to-date.
    
    11. Liability
    
    11.1 Liability Cap. The total combined liability of either party and its Affiliates towards the other party and its Affiliates, whether in contract, tort or any other theory of liability, under or in connection with the Agreement, this Addendum, and the Standard Contractual Clauses if entered into as described in Section 8.2 (Transfers of Data Out of the EEA) combined will be limited to limitations on liability or other liability caps agreed to by the parties in the Agreement, subject to Section 11.2 (Liability Cap Exclusions).
    
    11.2 Liability Cap Exclusions. Nothing in Section 11.1 (Liability Cap) will affect any party’s liability to data subjects under the third party beneficiary provisions of the Standard Contractual Clauses to the extent limitation of such rights is prohibited by the European Data Protection Legislation.
    
    12. Analytics
    
    Customer acknowledges and agrees that Bizwise may create and derive from processing related to the Services anonymised and/or aggregated data that does not identify Customer or any natural person, and use, publicize or share with third parties such data to improve Bizwise’s products and services and for its other legitimate business purposes.
    
    13. Notices
    
    Notwithstanding anything to the contrary in the Agreement, any notices required or permitted to be given by Bizwise to Customer may be given (a) in accordance with the notice clause of the Agreement; (b) to Bizwise’s primary points of contact with Customer; and/or (c) to any email provided by Customer for the purpose of providing it with Service-related communications or alerts. Customer is solely responsible for ensuring that such email addresses are valid.
    
    14. Effect of These Terms
    
    Notwithstanding anything to the contrary in the Agreement, to the extent of any conflict or inconsistency between this Addendum and the remaining terms of the Agreement, this Addendum will govern.
    
    Appendix 1
    
    Subject Matter and Details of the Data Processing
    
    This Appendix 1 is incorporated into the Addendum, and also forms part of the Standard Contractual Clauses (if such Standard Contractual Clauses are applicable to Customer).
    
    Data Importer
    
    The Data Importer (or Service Provider/Processor) is Bizwise, a provider of productivity solutions.
    
    Data Exporter
    
    The Data Exporter (or Business/Controller) is the Customer that is a party to the Addendum.
    
    Subject Matter
    
    Bizwise’s provision of the Services to Customer as set forth in the Agreement and the Addendum.
    
    Duration of the Processing
    
    The Term plus the period from the expiry of the Term until deletion of all Customer Personal Data by Bizwise in accordance with the Addendum.
    
    Nature and Purpose of the Processing
    
    Bizwise will receive, process, and store Customer Personal Data for the purposes of providing the Services to Customer in accordance with the Agreement and the Addendum, to communicate with Customer and its end users, to provide customer service, to monitor, maintain, and improve the Services, and to otherwise fulfill its obligations under the Agreement. Bizwise does not sell Customer Personal Data or Customer's end users’ personal data, and does not share such end users’ information with third parties for compensation or for those third parties’ own business interests.
    
    Categories of Personal Data
    
    First and last name
    Title
    Position
    Employer
    Contact information (company, email, phone, physical business address)
    ID data
    Connection data
    Localization data
    Other electronic data submitted, stored, sent, or received by an end user (which may include special categories of personal data under the GDPR, or sensitive personal data under the LGPD, to the extent that such data is submitted, stored, sent, or received by an end user; Bizwise does not request or require any sensitive or special categories of personal data for provision of the Services)
    Information related to invoices or payments made for the Bizwise service
    Usage information
    Sensitive Data
    
    Bizwise does not request or require any sensitive or special categories of personal data for provision of the Services)
    Customer Personal Data. Sensitive data may, from time to time be processed via the Services where Customer or its end users choose to include sensitive data within the communications that are transmitted using the Services or upload sensitive data to the Services. Customer is responsible for ensuring that suitable safeguards are in place prior to transmitting or processing, or prior to permitting Customer’s end users to transmit or process, any sensitive data via the Services.
    Data Subjects
    
    Employees, agents, advisors, and/or freelancers of Customer (who are natural persons), and/or individuals about whom data is provided to Bizwise via the Services by (or at the direction of) Customer
    End users authorised by Customer to use the Services
    Subprocessors
    
    Customer hereby consents to subprocessing by the entities set out at: https://Bizwise.com/dpa/subprocessors
    Appendix 2
    
    Technical and Organizational Security Measures
    
    As from the Addendum Effective Date, Bizwise will implement and maintain the technical and organizational Security Measures set out at https://Bizwise.com/security. Bizwise may update or modify such Security Measures from time to time provided that such updates and modifications do not materially decrease the overall security of the Services.
    
    The following table provides more information regarding the technical and organizational security measures set forth below:
    
    Technical and Organizational Security Measure
    
    Evidence of Technical and Organizational Security Measure
    
    Measures of pseudonymisation and encryption of personal data
    
    See Section 5.5 (Data Encryption) of the Security Overview
    
    Measures for ensuring ongoing confidentiality, integrity, availability and resilience of processing systems and services
    
    See Section 7.1 (Availability and Resiliency) and Section 7 (Business Continuity and Disaster Recovery) of the Security Overview
    
    Measures for ensuring the ability to restore the availability and access to personal data in a timely manner in the event of a physical or technical incident
    
    See Section 6 (Vulnerability and Incident Management) and Section 7 (Business Continuity and Disaster Recovery) of the Security Overview
    
    Processes for regularly testing, assessing and evaluating the effectiveness of technical and organizational measures in order to ensure the security of the processing
    
    See Section 1 (Purpose, Scope, and Organization), Application Security, Infrastructure Security, and Section 6.1 (Vulnerability Detection and Response) of the Security Overview
    
    Measures for user identification and authorization
    
    See Section 3 (Personnel Identity and Access Management) of the Security Overview
    
    Measures for the protection of data during transmission
    
    See Section 2.2 (Personnel Systems Configuration, Ownership, and Privacy), Section 5 (Data Classification and Processing), and Section 6 (Vulnerability and Incident Management) of the Security Overview
    
    Measures for the protection of data during storage
    
    See Infrastructure Security, Section 2.2 (Personnel Systems Configuration, Ownership, and Privacy), Section 4 (Provenance of Technology), and Section 5 (Data Classification and Processing) of the Security Overview
    
    Measures for ensuring physical security of locations at which personal data are processed
    
    See Data Center Security and Section 4 (Provenance of Technology) of the Security Overview
    
    Measures for ensuring events logging
    
    See Section 4.2 (Configuration and Change Management) and Section 6.2 (Incident Detection and Response) of the Security Overview
    
    Measures for ensuring system configuration, including default configuration
    
    See Section 4.1 (Software Development), Section 4.2 (Configuration and Change Management), and Section 6.2 (Incident Detection and Response) of the Security Overview
    
    Measures for internal IT and IT security governance and management
    
    See Section 1.3 (Risk Management Framework) and Section 2 (Personnel and Office Environment) of the Security Overview
    
    Measures for certification/assurance of processes and products
    
    See Infrastructure Security, Application Security, Section 1.3 (Risk Management Framework), Section 4 (Provenance of Technology) and Section 5 (Data Classification and Processing) of the Security Overview
    
    Measures for ensuring data minimisation
    
    More information about how Bizwise processes personal data is set forth in the Privacy Policy available at https://Bizwise.com/privacy.
    
    Measures for xensuring data quality
    
    More information about how Bizwise processes personal data is set forth in the Privacy Policy available at https://Bizwise.com/privacy.
    
    Measures for ensuring accountability
    
    More information about how Bizwise processes personal data is set forth in the Privacy Policy available at https://Bizwise.com/privacy.
    
    Measures for allowing data portability and ensuring erasure
    
    For data portability, see Section 5.3 (Customer Access) of the Security Overview For erasure, see this blog post.
    
    Technical and organizational measures to be taken by the (sub-) processor to provide assistance to the controller and, for transfers from a processor to a (sub-) processor, to the Customer.
    
    When Bizwise engages a Subprocessor under Section 9 (Subprocessors) of this Addendum, Bizwise and the Subprocessor enter into an agreement with data protection obligations substantially similar to those contained in this Addendum. Each Subprocessor agreement must ensure that Bizwise is able to meet its obligations to Customer. In addition to implementing technical and organizational measures to protect personal data, Subprocessors must (a) notify Bizwise in the event of a Security Incident so Bizwise may notify Customer; (b) delete personal data when instructed by Bizwise in accordance with Customer’s instructions to Bizwise; (c) not engage additional Subprocessors without Bizwise’s authorization; (d) not change the location where personal data is processed; or (e) process personal data in a manner which conflicts with Customer’s instructions to Bizwise.
    
    Appendix 3
    
    Cross Border Data Transfer Solutions
    
    1. Definitions
    
    For the purposes of the Clauses:
    
    For purposes of this Addendum, the terms below shall have the meanings set forth below. Capitalized terms that are used but not otherwise defined in this Addendum shall have the meanings set forth in the Agreement.
    
    1.1 "Standard Contractual Clauses” means, depending on the circumstances unique to Customer, any of the following:
    
    1.1.1 UK Standard Contractual Clauses, or;
    
    1.1.2 2021 Standard Contractual Clauses
    
    1.2 “UK Standard Contractual Clauses” means: Standard Contractual Clauses for data controller to data processor transfers approved by the European Commission in decision 2010/87/EU (“UK Controller to Processor SCCs”).
    
    1.3 “2021 Standard Contractual Clauses” means the Standard Contractual Clauses approved by the European Commission in decision 2021/914.
    
    2. Cross Border Data Transfer Solutions
    
    2.1 Order of Precedence. In the event the Services are covered by more than one Transfer Solution, the transfer of personal data will be subject to a single Transfer Solution in accordance with the following order of precedence: (a) the applicable Standard Contractual Clauses as set forth in Section 2.2 (UK Standard Contractual Clauses) or Section 2.3 (The 2021 Standard Contractual Clauses) of this Appendix 3; and, if neither (a) nor (b) is applicable, then (c) other data Transfer Solutions permitted under applicable Global Data Protection Legislation.
    
    2.2 UK Standard Contractual Clauses. The parties agree that the UK Standard Contractual Clauses will apply to personal data that is transferred via the Services from the United Kingdom, either directly or via onward transfer, to any country or recipient outside of the United Kingdom that is not recognized by the competent United Kingdom regulatory authority or governmental body for the United Kingdom as providing an adequate level of protection for personal data. For data transfers from the United Kingdom that are subject to the UK Standard Contractual Clauses, the UK Standard Contractual Clauses will be deemed entered into (and incorporated into this Addendum by this reference) and completed as follows:
    
    2.2.1 The UK Controller to Processor SCCs will apply where Bizwise is processing Customer Personal Data. The illustrative indemnification clause will not apply. Appendix 1 (Subject Matter and Details of the Data Processing) of this Addendum serves as Appendix I of the UK Controller to Processor SCCs. Appendix 2 (Technical and Organizational Security Measures) of this Addendum serves as Appendix II of the UK Controller to Processor SCCs.
    
    2.3 2021 Standard Contractual Clauses. The parties agree that the 2021 Standard Contractual Clauses will apply to personal data that is transferred via the Services from the European Economic Area or Switzerland, either directly or via onward transfer, to any country or recipient outside the European Economic Area or Switzerland that is not recognized by the European Commission (or, in the case of transfers from Switzerland, the competent authority for Switzerland) as providing an adequate level of protection for personal data. For data transfers from the European Economic Area that are subject to the 2021 Standard Contractual Clauses, the 2021 Standard Contractual Clauses will be deemed entered into (and incorporated into this Addendum by this reference) and completed as follows:
    
    2.3.1 Module Two (Controller to Processor) of the 2021 Standard Contractual Clauses will apply where Customer is a controller of Customer Personal Data and Bizwise is processing Customer Personal Data.
    
    2.3.2 Module Three (Processor to Processor) of the 2021 Standard Contractual Clauses will apply where Customer is a processor of Customer Personal Data and Bizwise is processing Customer Personal Data.
    
    2.3.3 For each Module, where applicable:
    
    (a) in Clause 7 of the 2021 Standard Contractual Clauses,the optional docking clause will not apply;
    (b) in Clause 9 of the 2021 Standard Contractual Clauses, Option 2 “General Written Authorisation” will apply and the time period for prior notice of Subprocessor changes will be as set forth in Section 9 (Subprocessors) of this Addendum;
    (c) in Clause 11 of the 2021 Standard Contractual Clauses, the optional language will not apply;
    (d) in Clause 17 (Option 1), the 2021 Standard Contractual Clauses will be governed by Irish law;
    (e) in Clause 18(b) of the 2021 Standard Contractual Clauses, disputes will be resolved before the courts of Ireland;
    (f) in Annex I, Part A (List of Parties) of the 2021 Standard Contractual Clauses:
    (i) Data Exporter: Customer.
    (ii) Contact details: The email address(es) designated by Customer in Customer’s account via its notification preferences.
    (iii) Data Exporter Role: The Data Exporter’s role is set forth in Section 3.1 (Roles and Regulatory Compliance; Authorization) of this Addendum. The parties acknowledge and agree that with regard to the processing of Customer Personal Data, Customer may act either as a controller or processor and Bizwise is a processor. Bizwise will process Customer Personal Data in accordance with Customer’s Instructions as set forth in Section 3.2.1.
    (iv) Signature and Date: By entering into the Agreement, Data Exporter is deemed to have signed these Standard Contractual Clauses incorporated herein, including their Annexes, as of the effective date of the Agreement.
    (v) Data Importer: Bizwise, Inc.
    (vi) Address: 44 Tehama Street, 4th Floor, San Francisco, CA 94015
    (vii) Contact details: Bizwise Data Security Team – data@bizwise.com
    (viii) Data Importer Role: The Data Importer’s role is set forth in Section 3.1 (Roles and Regulatory Compliance; Authorization) of this Addendum. The parties acknowledge and agree that with regard to the processing of Customer Personal Data, Customer may act either as a controller or processor and Bizwise is a processor. Bizwise will process Customer Personal Data in accordance with Customer’s Instructions as set forth in Section 3.2.1.
    (xi) Signature and Date: By entering into the Agreement, Data Importer is deemed to have signed these Standard Contractual Clauses, incorporated herein, including their Annexes, as of the Effective Date of the Agreement.
    (g) in Annex I, Part B (Description of Transfer) of the 2021 Standard Contractual Clauses:
    (i) The categories of data subjects are described in the “Data Subjects” Section of Appendix 1 (Subject Matter and Details of the Data Processing) of this Addendum.
    (ii) The categories of personal data transferred is described in the “Categories of Personal Data” Section of Appendix 1 (Subject Matter and Details of the Data Processing) of this Addendum.
    (iii) The Sensitive Data transferred is described in the “Sensitive Data” Section of Appendix 1 (Subject Matter and Details of the Data Processing) of this Addendum.
    (iv) Signature and Date: By entering into the Agreement, Data Exporter is deemed to have signed these Standard Contractual Clauses incorporated herein, including their Annexes, as of the effective date of the Agreement.
    (v) The nature of the processing is described in the “Nature and Purpose of the Processing” Section of Appendix 1 (Subject Matter and Details of the Data Processing) of this Addendum.
    (vi) The purpose of the processing is described in the “Nature and Purpose of the Processing” Section of Appendix 1 (Subject Matter and Details of the Data Processing) of this Addendum.
    (vii) The period for which personal data will be retained and the criteria used to determine that period is as follows: Prior to the termination of the Agreement, Bizwise will process stored Customer Personal Data for the permitted purposes set forth in Section 3.1.1. (Customer's Instructions) until Customer elects to delete such Customer Personal Data via the Services. Prior to the termination of the Agreement, Customer agrees that it is solely responsible for deleting Customer Personal Data via the Services. Upon termination of the Agreement, Bizwise will (i) provide Customer thirty (30) days after the effective date of termination to obtain a copy of any stored Customer Personal Data via the Services, and (ii) delete any stored Customer Personal Data within thirty (30) days upon customer request, unless alternate timeframes for retention and/or deletion are otherwise set forth in the Agreement. Any Customer Personal Data archived on Bizwise’s back-up systems will be securely isolated and protected from any further processing, except as otherwise required by applicable law or regulation.
    (viii) For transfers to Subprocessors, the subject matter, nature, and duration of the processing is set forth at https://Bizwise.com/dpa/subprocessors.
    (h) in Annex I,Part C of the 2021 Standard Contractual Clauses: The Irish Data Protection Commission will be the competent supervisory authority.
    (i) Appendix 2 (Technical and Organizational Security Measures) of this Addendum serves as Annex II of the Standard Contractual Clauses.
    2.4 Conflict. To the extent there is any direct conflict between the Standard Contractual Clauses and any other terms in this Addendum, the Agreement, or the Privacy Policy, the provisions of the Standard Contractual Clauses will prevail.
    `,
  },
];

export interface Policies {
  'Privacy Policy': Paragraph[],
  'Cookie Policy': Paragraph[],
  'Terms of Service': Paragraph[],
  'Data Protection Addendum': Paragraph[],
}

export const policies: Policies = {
  'Privacy Policy': privacyPolicy,
  'Cookie Policy': cookiePolicy,
  'Terms of Service': termsOfService,
  'Data Protection Addendum': dataProtection,
};
