import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  breakpoint, spacing, Theme,
} from 'src/theme';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import clsx from 'clsx';
import { useBreakpointToggleStyles, useScrollableRef } from 'src/hooks';
import SectionContainer from '../shared/SectionContainer';
import { policies, Policies } from './policies';

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: 'auto',
    overflow: 'unset',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: spacing(10),
    paddingBottom: spacing(40),
    paddingRight: spacing(40),
    [breakpoint.down('md')]: {
      flexDirection: 'column',
      paddingRight: 0,
      marginTop: 0,
    },
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
    borderRadius: 10,
    position: 'sticky',
    top: spacing(40),
  },
  menuTitle: {
    paddingLeft: spacing(8),
    marginBottom: spacing(3),
    fontWeight: 700,
  },
  menuItem: {
    textAlign: 'left',
    paddingTop: spacing(3),
    paddingLeft: spacing(8),
    paddingBottom: spacing(3),
    paddingRight: spacing(8),
    color: '#003960',
    fontWeight: 600,
    '&:hover': {
      background: theme.palette.secondary.LIGHT_BLUE,
    },
  },
  selectedPill: {
    backgroundColor: theme.palette.primary.BLUE,
    borderColor: theme.palette.primary.BLUE,
    '&:hover': {
      backgroundColor: theme.palette.primary.BLUE,
      borderColor: theme.palette.primary.BLUE,
    },
  },
  selectedMenuItem: {
    background: theme.palette.secondary.BLUE_2,
    color: theme.palette.primary.WHITE,
    '&:hover': {
      background: theme.palette.secondary.BLUE_2,
      color: theme.palette.primary.WHITE,
    },
  },
  menuPill: {
    marginRight: spacing(1.5),
    marginBottom: spacing(2),
  },
  textContainer: {
    width: '60%',
    marginLeft: spacing(10),
    [breakpoint.down('md')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: spacing(8),
    },
  },
  bodyText: {
    '& ul': {
      paddingLeft: spacing(5),
      listStyle: 'disc',
      '& ul': {
        listStyle: 'circle',
      },
    },
    '& ol': {
      paddingLeft: spacing(5),
      listStyle: 'auto',
      '& a': {
        textDecoration: 'underline',
      },
    },
  },
}));

// TODO: Show policy based on URL hash (e.g: /policy#security)
const PoliciesSection: React.FC = () => {
  const [selectedPolicy, setSelectedPolicy] = useState<keyof Policies>('Privacy Policy');
  const { ref: contentRef, handleScroll: handleScrollToContent } = useScrollableRef();

  function setPolicy(policy: keyof Policies) {
    setSelectedPolicy(policy);
    if (contentRef.current && contentRef.current.getBoundingClientRect().top < 0) {
      handleScrollToContent();
    }
  }

  const breakpointToggleStyles = useBreakpointToggleStyles();
  const classes = useStyles();

  const policiesKeys = Object.keys(policies) as Array<keyof Policies>;

  return (
    <SectionContainer className={classes.container}>
      <div className={classes.content} ref={contentRef}>
        <div>
          <div className={breakpointToggleStyles.upToMd}>
            {policiesKeys.map((policy) => (
              <Button
                className={clsx(
                  classes.menuPill,
                  selectedPolicy === policy && classes.selectedPill,
                )}
                disabled={selectedPolicy === policy}
                variant="filled"
                color="white"
                roundFull
                key={policy}
                onClick={() => setSelectedPolicy(policy)}
              >
                <Typography
                  variant="p3"
                  color={selectedPolicy === policy ? 'white' : 'blackish'}
                  style={{ fontWeight: 600 }}
                >
                  {policy}
                </Typography>
              </Button>
            ))}
          </div>
          <div className={clsx(classes.menu, breakpointToggleStyles.aboveMd)}>
            <Typography className={classes.menuTitle} variant="h3" color="blue">
              POLICIES
            </Typography>
            {policiesKeys.map((policy) => (
              <button onClick={() => setPolicy(policy)} type="button" key={policy}>
                <Typography
                  className={clsx(
                    classes.menuItem,
                    selectedPolicy === policy && classes.selectedMenuItem,
                  )}
                  variant="p2"
                  color="textPrimary"
                >
                  {policy}
                </Typography>
              </button>
            ))}
          </div>
        </div>
        <div className={classes.textContainer}>
          <Typography variant="h2" color="blue" underline>
            {selectedPolicy}
          </Typography>
          {policies[selectedPolicy].map(({ html, heading }) => (
            <React.Fragment key={heading}>
              <Typography
                variant="h3"
                color="blue"
                style={{
                  marginTop: spacing(8),
                  marginBottom: spacing(8),
                  fontWeight: 700,
                }}
              >
                {heading}
              </Typography>
              <Typography variant="p2" color="blackish" className={classes.bodyText}>
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: html }} />
              </Typography>
            </React.Fragment>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

export default PoliciesSection;
